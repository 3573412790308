<template>
  <v-container fluid>
    <h3 class="mt-4 primary--text">Histroy dan Pelayanan Pasien</h3>
    <v-col cols="12" md="12" lg="12">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="9" lg="9" sm="9"
            ><v-autocomplete
              v-model="model"
              return-object
              hide-no-data
              item-text="text"
              item-value="text"
              outlined
              dense
              clearable
              :disabled="isReadonly"
              hide-selected
              prepend-icon="mdi-database-search"
              :items="getPatients"
              @change="getFullPasien"
              :search-input.sync="search"
              label="Berdasarkan Nama Pasien/No. RM/Tanggal Lahir"
            ></v-autocomplete
          ></v-col>
          <v-col cols="12" md="3" lg="3" sm="3">
            <v-btn color="primary" class="btn-block" medium @click="reset">
              Reset Searching
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-row>
      <v-col cols="12" md="5" lg="5" sm="5">
        <fieldset class="scheduler-border">
          <legend class="scheduler-border">
            <p class="text-h6 primary--text">Demografi Pasien</p>
          </legend>
          <v-row>
            <v-col
              v-for="(v, i) in master.demografi"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-1 mb-0 pt-2 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.demografi[i] = e)"
                :value="data.demografi[i]"
                vclass="c-text-field"
                :data="v.data"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </fieldset>
        <fieldset class="scheduler-border">
          <legend class="scheduler-border">
            <p class="text-h6 primary--text">Cara Bayar</p>
          </legend>
          <v-row>
            <v-col
              v-for="(v, i) in master.menu_cara_bayar"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="12"
              cols="12"
              class="mt-1 mb-0 pt-1 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.menu_cara_bayar[i] = e)"
                :value="data.menu_cara_bayar[i]"
                vclass="c-text-field"
                :property="v.property"
                :data="v.data"
                :label="v.label"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </fieldset>
        <fieldset class="scheduler-border">
          <legend class="scheduler-border">
            <p class="text-h6 primary--text">Data Pelengkap</p>
          </legend>
          <v-row>
            <v-col
              v-for="(v, i) in master.data_pelangkap"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-1 mb-0 pt-2 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.data_pelangkap[i] = e)"
                :value="data.data_pelangkap[i]"
                vclass="c-text-field"
                :property="v.property"
                :data="v.data"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </fieldset>
      </v-col>

      <v-col cols="12" md="7" lg="7" sm="7">
        <fieldset class="scheduler-border">
          <legend class="scheduler-border mb-2">
            <p class="text-h6 primary--text">History Kunjungan Poliklinik</p>
          </legend>

          <!-- {{ dataTable }} -->
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="dataTable.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :loading="dataTable.isLoading"
              :headers="dataTable.headerData"
              :search="dataTable.search"
              :items="dataTable.datas"
              :sort-by.sync="dataTable.config.table.sortBy"
              :sort-desc.sync="dataTable.config.table.sortDesc"
              :items-per-page="dataTable.config.table.itemsPerPage"
              :page.sync="dataTable.config.table.page"
              :server-items-length="dataTable.serverLength"
              :options.sync="dataTable.options"
              height="240pt"
              item-key="id"
              class="m-1"
              multi-sort
              hide-default-footer
              fixed-header
              @page-count="dataTable.config.table.pageCount = $event"
              @pagination="dataTable.pagination = $event"
            >
              <template #[`item.folio_no`]="{ item }">
                <!-- <v-btn
                x-small
                fab
                color="primary"
                @click="setFolioPasien(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>&nbsp; -->
                <v-btn
                  x-small
                  fab
                  :color="colorstatus(item)"
                  dark
                  @click="setFolioPasien(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template #[`item.service_date`]="{ item }">
                <b>{{ item.service_date | moment("DD MMMM YYYY") }}</b>
              </template>
              <template #[`item.folio_status`]="{ item }">
                <!-- {{ item.folio_status | moment("DD MMMM YYYY") }} -->
                {{ folioStatus(item.folio_status) }}
              </template>
            </v-data-table>
            <div class="row align-center pb-3">
              <div class="col-md-6 col-12 order-md-0 order-1 pt-0">
                <v-data-footer
                  class="float-left"
                  :pagination="dataTable.pagination"
                  :prev-icon="null"
                  :next-icon="null"
                  :first-icon="null"
                  :last-icon="null"
                  :items-per-page-options="[10, 15, 50, 100, -1]"
                  :options.sync="dataTable.options"
                />
              </div>
              <div class="col-md-6 col-12 order-md-1 order-0 pb-0 pb-md-4">
                <v-pagination
                  v-model="dataTable.config.table.page"
                  :length="dataTable.config.table.pageCount"
                  total-visible="7"
                  circle
                />
              </div>
            </div>
          </v-card>
        </fieldset>
        <fieldset class="scheduler-border">
          <legend class="scheduler-border mb-2">
            <p class="text-h6 primary--text">File Pasien</p>
          </legend>
          <v-card>
            <v-card-title>Form Input File Pasien</v-card-title>
            <v-card-text>
              <v-file-input
                placeholder="Pilih File Pasien"
                prepend-icon="mdi-paperclip"
                color="deep-purple accent-4"
                v-model="uploadFile.file"
                accept=".pdf,.jpeg"
                label="File input"
              ></v-file-input>

              <v-select
                :items="[
                  'SIK',
                  'KTP',
                  'LP',
                  'KK',
                  'LK',
                  'LPI',
                  'LKI',
                  'EKG',
                  'SPIROMETRI',
                ]"
                dense
                v-model="uploadFile.jenisFile"
                class="mt-2"
                label="Jenis File Pasien"
              ></v-select>

              <v-btn color="primary" @click="uploadfilepasien"
                >Upload File</v-btn
              >
              <hr />
              <table class="table table-bordered table-sm" width="100%">
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      style="vertical-align: middle; width: 5%"
                    >
                      No
                    </th>
                    <th
                      class="text-center"
                      style="vertical-align: middle; width: 20%"
                    >
                      Jenis File
                    </th>
                    <th class="text-center" style="vertical-align: middle">
                      File
                    </th>
                    <th
                      class="text-center"
                      style="vertical-align: middle; width: 30%"
                    >
                      Tanggal Di Upload
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) of listFile" :key="index">
                    <td style="vertical-align: middle" class="text-center">
                      {{ index + 1 }}
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      {{ item.jenis }}
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      <span v-for="(item2, index2) of item.file" :key="index2">
                        <a href="#" @click="open(item2)">Download</a>
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      {{ item.created_at }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </fieldset>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import SmartWidget from '@/components/SmartWidget.vue'
// eslint-disable-next-line no-unused-vars
import {
  apiKedua,
  baseUrlV2,
  errorMsg,
  successMsg
} from '../../plugins/supports'

// import { mapMutations } from 'vuex'

// import axios from 'axios'
export default {
  components: {
    SmartWidget
  },
  props: {
    no_rm: {
      require: false,
      type: String,
      default: () => ''
    },
    folio_id: {
      require: false,
      type: [String, Number],
      default: () => ''
    }
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',

      // menukanan: [
      //   {
      //     text: 'Data Profile',
      //     level: 3,
      //     to: '#test0'
      //   },
      //   {
      //     text: 'Data Pelengkap',
      //     level: 3,
      //     to: '#test1'
      //   },
      //   {
      //     text: 'History Pelayanan',
      //     level: 3,
      //     to: '#test2'
      //   }
      // ],
      loader: null,
      uploadFile: {
        file: null,
        jenisFile: ''
      },
      pegawai: '',
      descriptionLimit: 60,
      firebasePasien: '',
      patients: [],
      dataPatient: {},
      isLoading: false,
      loadingHistoryPelayanan: true,
      pagination: {},
      isReadonly: false,
      totalHistoryPelayanan: 0,
      model: null,
      listFile: '',
      noRm: null,
      search: null,
      history: [],
      dataTable: {
        isLoading: false,
        headerData: [
          {
            text: 'Action',
            value: 'folio_no',
            width: '100px',
            align: 'center'
          },
          {
            text: 'Status',
            value: 'folio_status',
            width: '100px',
            align: 'left'
          },
          {
            text: 'Tanggal Folio',
            value: 'service_date',
            width: '180px',
            align: 'left'
          },
          {
            text: 'Dokter/Unit',
            value: 'namaSubUnit',
            width: '180px',
            align: 'left'
          },
          {
            text: 'Nama Unit/Poli',
            value: 'namaUnit',
            width: '210px',
            align: 'left'
          },
          {
            text: 'No.Folio',
            value: 'noFolio',
            width: '120px',
            align: 'center'
          },
          {
            text: 'Created By',
            value: 'user_name',
            width: '290px',
            align: 'left'
          }
        ],
        search: '',
        datas: [],
        config: {
          table: {
            page: 1,
            pageCount: 0,
            sortBy: ['service_date'],
            sortDesc: [],
            itemsPerPage: 5,
            itemKey: 'id'
          }
        },
        serverLength: 1,
        pagination: {},
        options: {}
      },
      master: {
        demografi: {
          sapaan: {
            widget: 'wtext',
            label: 'Alias',
            data: null,
            col: 2
          },
          nama_pasien: {
            widget: 'wtext',
            label: 'Nama Pasien',
            data: null,
            col: 4
          },
          nama_lain: {
            label: 'Nama Lain',
            widget: 'wtext',
            data: null,
            col: 4
          },
          umur: {
            label: 'Umur',
            widget: 'wtext',
            data: null,
            col: 2
          },
          nik: {
            label: 'NIK',
            widget: 'wtext',
            data: null,
            col: 3
          },
          no_rekam_medis: {
            label: 'No. RM',
            widget: 'wtext',
            data: null,
            col: 3
          },
          tanggal_lahir: {
            label: 'Tanggal Lahir',
            widget: 'wdate',
            data: null,
            col: 3
          },
          tempat_lahir: {
            label: 'Tempat Lahir',
            widget: 'wtext',
            data: null,
            col: 3
          },
          jenis_kelamin: {
            label: 'Jenis Kelamin',
            widget: 'wradio',
            col: 4,
            data: [
              { text: 'Laki-Laki', value: 'Laki-Laki' },
              { text: 'Perempuan', value: 'Perempuan' }
            ]
          },
          alamat: {
            label: 'Alamat Lengkap',
            widget: 'wtext',
            col: 8,
            data: null
          }
        },
        data_pelangkap: {
          status_menikah: {
            label: 'Status Menikah',
            widget: 'wtext',
            data: null,
            col: 4
          },
          pekerjaan: {
            label: 'Pekerjaan',
            widget: 'wtext',
            data: null,
            col: 4
          },
          agama: {
            label: 'Agama',
            widget: 'wtext',
            data: null,
            col: 4
          },
          golongan_darah: {
            label: 'Golongan Darah',
            widget: 'wtext',
            data: null,
            col: 6
          },
          kebangsaan: {
            label: 'Kebangsaan',
            widget: 'wtext',
            data: null,
            col: 6
          },
          pendidikan: {
            label: 'Pendidikan',
            widget: 'wtext',
            data: null,
            col: 7
          },
          ras: {
            label: 'Ras/Etnis',
            widget: 'wtext',
            data: null,
            col: 5
          },
          jenis_pasien: {
            label: 'Jenis Pasien',
            widget: 'wtext',
            data: null,
            col: 5
          },
          kelompok_pasien: {
            label: 'Kelompok Pasien',
            widget: 'wtext',
            data: null,
            col: 7
          },
          alasan: {
            label: 'Alasan',
            widget: 'wtextarea',
            data: null,
            col: 12
          },
          penghasilan: {
            widget: 'wradio',
            data: [
              {
                text: 'tidak ada',
                value: 'tidak ada'
              },
              {
                text: '< 1 juta',
                value: '< 1 juta'
              },
              {
                text: '1 - 2,9 juta',
                value: '1 - 2,9 juta'
              },
              {
                text: '3-4,9 juta',
                value: '3-4,9 juta'
              },
              {
                text: '5-9,9 juta',
                value: '5-9,9 juta'
              },
              {
                text: '10-14,9 juta',
                value: '10-14,9 juta'
              },
              {
                text: '>15 juta',
                value: '>15 juta'
              }
            ]
          },
          bahasa: {
            widget: 'wradio',
            data: [
              {
                text: 'indonesia',
                value: 'indonesia'
              },
              {
                text: 'asing',
                value: 'asing'
              },
              {
                text: 'daerah',
                value: 'daerah'
              },
              {
                text: 'penerjemah',
                value: 'penerjemah'
              }
            ]
          }
        },
        menu_cara_bayar: {
          cara_bayar: {
            label: 'Cara Bayar',
            widget: 'wtext',
            data: null,
            col: 12
          },
          isPasienBersedia: {
            widget: 'wcheckbox',
            label: null,
            col: 12,
            data: [
              {
                text: 'Pasien Bersedia Menanggung sendiri atas biaya tidak ditanggung oleh perusahaan',
                value:
                  'Pasien Bersedia Menanggung sendiri atas biaya tidak ditanggung oleh perusahaan'
              }
            ]
          },
          kartu_anggota: {
            label: 'Kartu Anggota',
            widget: 'wtext',
            data: null,
            col: 6
          },
          kartu_id: {
            label: 'Kartu ID',
            widget: 'wtext',
            data: null,
            col: 6
          }
        },
        history: {}
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          sapaan: '',
          nik: '',
          alamat: '',
          nama_lain: '',
          umur: ''
        },
        data_pelangkap: {
          status_menikah: ''
        },
        menu_cara_bayar: {
          cara_bayar: '',
          isPasienBersedia: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  methods: {
    // ...mapMutations(['set_contents']),
    // localStorage.setItem('')

    open (item) {
      window
        .open(
          'https://hr.rs-syafira.com/elFinder_hr/file_get_contents_all.php?dir=//100.100.100.6/erm/PASIEN/' +
            this.$route.query.no_rm +
            '/' +
            item,
          '_blank'
        )
        .focus()
    },

    getdata () {
      var listFile = []
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/jenis-file?no_rm=' + this.$route.query.no_rm,
        (res) => {
          const { con, msg, results } = res
          if (con) {
            // this.listFile = results;
            results.forEach((item) => {
              listFile.push({
                file: JSON.parse(item.file),
                link: '',
                jenis: item.jenis,
                created_at: item.created_at
              })
            })
            // for (const key in results) {
            //   console.log(key);
            //   // listFile.push({
            //   //   file: JSON.parse(key.file),
            //   // });
            // }
            this.listFile = listFile
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    colorstatus (RESULTS) {
      if (RESULTS.folio_status === '1') {
        return ''
      } else if (RESULTS.folio_status === '3') {
        return 'green'
      } else if (RESULTS.folio_status === '4') {
        return 'red'
      } else if (RESULTS.folio_status === '5') {
        return 'red'
      } else {
        return 'primary'
      }
    },

    uploadfilepasien () {
      // const formData = new FormData()
      // console.log(this.$refs.fileInput)
      // console.log(this.uploadFile)

      if (!this.uploadFile.jenisFile) {
        errorMsg('JENIS FILE TIDAK BOLEH KOSONG')
        return
      }
      if (!this.uploadFile.file) {
        errorMsg('FILE TIDAK BOLEH KOSONG')
        return
      }
      const formData = new FormData()
      formData.append('file', this.uploadFile.file)
      formData.append('jenisFile', this.uploadFile.jenisFile)
      // formData.append('file', this.$refs.fileInput.files[0])
      // formData.append('jenisFile', this.jenisFile)
      formData.append('noRm', this.$route.query.no_rm)
      formData.append('akun', this.pegawai.employee_id)

      var ini = this
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'pasien/upload-file',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: function (response) {
          console.log(response)

          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }

          setTimeout(() => {
            ini.getdata()

            ini.uploadFile.file = null
            ini.uploadFile.jenisFile = null
          }, 1000)
          // Handle success
        },
        error: function (xhr, status, error) {
          console.error(error)
          // Handle error
        }
      })
    },

    folioStatus (status) {
      if (status === '1') {
        return 'Reservasi'
      } else if (status === '3') {
        return 'Buka'
      } else if (status === '4') {
        return 'Tutup'
      } else if (status === '5') {
        return 'Batal'
      } else {
        return 'Batal'
      }
    },
    // reset pencarian
    reset () {
      this.$refs.form.reset()
      localStorage.removeItem('pasien')
    },
    setFolioPasien (v) {
      // eslint-disable-next-line no-unused-vars
      var hasil = ''
      if (v.folio_status === '1') {
        hasil = 'Reservasi'
      } else if (v.folio_status === '3') {
        hasil = 'Buka'
      } else if (v.folio_status === '4') {
        hasil = 'Tutup'
      } else if (v.folio_status === '5') {
        hasil = 'Batal'
      } else {
        hasil = 'Batal'
      }
      const array = ['1', '4', '5']
      if (array.includes(v.folio_status.toString())) {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Perhatian! Apakah Anda Yakin?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Buka Asesmen Ini',
          cancelButtonText: 'Tidak Jadi Batal',
          html: `Ingin Membuka Status Asesment Ini Karna Status Pasien Ini  <b>${hasil}</b> Untuk Status <b>Orderan Resep, Orderan Laboratorium, Radiologi Tidak Bisa Diorder</b>`
        }).then((result) => {
          if (result.value) {
            // eslint-disable-next-line no-undef
            console.log(v)
            setTimeout(() => {
              this.snack_text =
                'Berhasil Set Folio:' +
                v.noFolio +
                ' tanggal: ' +
                v.service_date
              this.snackbar = true
              this.dialogShow = false
              // this.backButton()
              this.dialogDisableNBtn = false
              this.dialogDisablePBtn = false
              this.$router.replace({
                name: 'FormRawatJalan',
                query: {
                  no_rm: v.medrec_no,
                  folio_id: v.folio_id,
                  registration_id: v.registration_id,
                  sub_unit_id: v.sub_unit_id,
                  number: Math.random()
                }
              })
            }, 1000)
          } else {
            // this.disablebuttonSimpan = false
            // this.color = 'primary'
            // resetBtnLoading(btn, html)
            errorMsg('Nggak Jadi Deh !!!')
          }
        })
      } else {
        setTimeout(() => {
          this.snack_text =
                'Berhasil Set Folio:' +
                v.noFolio +
                ' tanggal: ' +
                v.service_date
          this.snackbar = true
          this.dialogShow = false
          // this.backButton()
          this.dialogDisableNBtn = false
          this.dialogDisablePBtn = false
          this.$router.replace({
            name: 'FormRawatJalan',
            query: {
              no_rm: v.medrec_no,
              folio_id: v.folio_id,
              registration_id: v.registration_id,
              sub_unit_id: v.sub_unit_id,
              number: Math.random()
            }
          })
        }, 1000)
      }
    },
    getFullPasien (val) {
      console.log('Hasil' + val.value)

      this.noRm = val
      this.data.demografi.cara_bayar = { text: 'APS', value: 'APS' }
      this.data.demografi.jenis_kelamin = {
        text: 'Laki-Laki',
        value: 'laki-laki'
      }
      localStorage.removeItem('form-asesment-rawat-jalan')
      localStorage.removeItem('form-resume-rawat-jalan')
      localStorage.removeItem('folioIdYangDipakai')
      this.data.demografi.tanggal_lahir = '2022-05-10'
      this.data.demografi.no_rekam_medis = val.value

      // apiKedua.get().then()

      this.getHistoryPelayananSimrs({ rm: val.value })
      // this.firebasePasien.no_rm = this.data.demografi.no_rekam_medis

      this.getDataPasien(val.value)

      this.$router.replace({
        name: 'ProfilePasien',
        query: {
          no_rm: val.value,
          folio_id: '',
          registration_id: '',
          number: Math.random()
        }
      })
    },
    getHistoryPelayananSimrsFromApi () {
      this.loadingHistoryPelayanan = true
    },
    getHistoryPelayananSimrs (v) {
      const query = new URLSearchParams(v).toString()
      console.log(v)
      this.isReadonly = true
      apiKedua
        .get(`emr/history-kunjungan?${query}`)
        .then((response) => {
          localStorage.setItem(
            'historyPelayananSimrs',
            JSON.stringify(response.data.results)
          )

          this.dataTable.datas = response.data.results
            ? response.data.results.data ?? []
            : []
          this.dataTable.serverLength = response.data.results
            ? parseInt(response.data.results.total) ?? 0
            : 0
          this.isReadonly = false
        })
        .catch((error) => {
          this.isReadonly = false
          console.log(error)
        })
    },
    getDataPasien (v) {
      this.isReadonly = true
      localStorage.removeItem('pasien')
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          console.log(response)
          if (response.data.con) {
            this.dataPatient = response.data.results
            this.data.demografi = this.dataPatient
            this.data.menu_cara_bayar = this.dataPatient
            this.data.data_pelangkap = this.dataPatient
            this.data.data_pelangkap.agama = this.dataPatient.religion
            this.data.demografi.jenis_kelamin = {
              value: this.dataPatient.jenis_kelamin,
              text: this.dataPatient.jenis_kelamin
            }
            localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

            this.getHistoryPelayananSimrs({
              rm: v,
              ...this.dataTable.options,
              search: this.dataTable.search
            })
            this.isReadonly = false
          } else {
            this.isReadonly = false
          }
        })
        .catch(function (error) {
          this.isReadonly = false
          console.log(error)
        })
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))

      this.getDataPasien(this.$route.query.no_rm)
      this.getdata()
      localStorage.setItem('drawer', true)
      localStorage.setItem('minivar', true)
    }
  },

  computed: {
    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a'
        }
      })
    },
    getPatients () {
      return this.patients.map((v) => ({
        text: v.text + '' + ' No. RM ' + v.id,
        value: v.id
      }))
    },
    totalUmur () {
      console.log(this.data.demografi.tanggal_lahir)

      return 0
    }
  },
  watch: {
    'data.demografi.tanggal_lahir' (n, o) {
      const a = this.data.demografi.tanggal_lahir.substring(0, 4)
      const b = new Date()
      console.log('total umur => ', b.getFullYear() - a)
      this.data.demografi.umur = (b.getFullYear() - a).toString()
    },
    'dataTable.options' (a, b) {
      this.getHistoryPelayananSimrs({
        rm: this.$route.query.no_rm,
        ...this.dataTable.options,
        search: this.dataTable.search
      })
    },
    'dataTable.search' (a, b) {
      this.getHistoryPelayananSimrs({
        rm: this.$route.query.no_rm,
        ...this.dataTable.options,
        search: this.dataTable.search
      })
    },
    search (val) {
      apiKedua
        .get('emr/cari-pasien?q=' + val, {})
        .then((response) => (this.patients = response.data.results))
        .catch(function (error) {
          // handle error
          console.log(error)
        })
        .then(function () {
          // always executed
        })
    },
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  }
}
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
